var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"responsive-bar"},[_c('Loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":true,"opacity":0.5,"loader":"dots"},on:{"update:active":function($event){_vm.isLoading=$event}}}),(_vm.assetData)?_c('b-modal',{staticClass:"p-2 modal-content",attrs:{"id":"report-found-modal","hide-header-close":"","hide-header":"","hide-footer":"","centered":""}},[(!_vm.isLoading)?_c('div',{staticClass:"row"},[_c('h5',{staticClass:"modal-title text-center",domProps:{"innerHTML":_vm._s(
          _vm.$t('detailasset.re_found_asset', {
            assetId: _vm.assetData.id_fixed_asset,
          })
        )}})]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t("asset.note")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.note_lost),expression:"note_lost"}],staticClass:"comment-textarea w-100",domProps:{"value":(_vm.note_lost)},on:{"input":function($event){if($event.target.composing)return;_vm.note_lost=$event.target.value}}}),_c('div',{staticClass:"row justify-content-center"},[_c('button',{staticClass:"btn btn-secondary col-6",attrs:{"type":"button"},on:{"click":function($event){_vm.note_lost = '';
          _vm.$bvModal.hide('report-found-modal');
          _vm.$router.back();}}},[_vm._v(" "+_vm._s(_vm.$t("btn.canc"))+" ")]),_c('button',{staticClass:"btn btn-primary col-6",attrs:{"type":"button","disabled":!_vm.note_lost},on:{"click":_vm.reportFound}},[_vm._v(" "+_vm._s(_vm.$t("btn.conf"))+" ")])])]):_vm._e(),_c('Footer'),_c('div',{staticClass:"scan-qr"},[_c('div',{},[_c('h1',{staticClass:"header-text"},[_c('div',{staticClass:"topic"},[_vm._v(_vm._s(_vm.$t("scan.scanqr")))])])]),_vm._m(0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"line-break"})])
}]

export { render, staticRenderFns }